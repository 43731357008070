<template>
  <div>
    <div
      class="min-h-50 d-flex w-100 align-items-center justify-content-center"
      v-if="!hasSnapshot && !loading"
    >
      <div class="text-center">
        <b-icon icon="calculator" class="h1" />
        <p class="my-3">Ainda não há informações de atividades econômicas.</p>
        <b-button @click="handleSyncronize()" v-if="is_provider && enabled">
          <b-icon icon="arrow-down-up" class="mr-2" />
          <span>Sincronizar agora</span>
        </b-button>
      </div>
    </div>
    <div v-else>
      <div class="d-flex align-items-center justify-content-start">
        <small class="mr-2">
          Ultima atualização {{ activity.updated_at | dateFull }}
        </small>
        <unicon
          v-if="is_provider && enabled"
          name="redo"
          @click="handleSyncronize()"
          class="pointer small"
          style="font-size: 12px"
          height="16"
          width="16"
        />
      </div>
      <div id="location-techinical" class="mt-3">
        <b-card class="details activity mr-4">
          <div>
            <strong>Suas atividades</strong>
          </div>
          <div class="mt-4" v-if="activity.data">
            <div
              v-for="(act, index) in activity.data.provider_economic_activities"
              :key="index"
            >
              <section
                class="d-flex w-100 justify-content-between align-items-center"
              >
                <a
                  v-b-popover.hover.top="
                    'clique para ver a descrição dessa atividade '
                  "
                  class="mb-0 pointer icon-action"
                  @click="handleShowDetailActivity(act)"
                  v-b-modal.modal-detail-activity
                >
                  {{ act.description }}
                </a>
                <b-icon
                  v-if="false"
                  v-b-popover.hover.top="
                    `Marcar como ${invertValue ? '' : 'não'} atende`
                  "
                  @click="handleClickChange()"
                  :icon="invertValue ? 'x' : 'check'"
                  class="h3 pointer ml-4 mr-1 my-0"
                  :variant="invertValue ? 'danger' : 'success'"
                />
              </section>
              <hr class="mt-1" />
            </div>
          </div>
        </b-card>
        <b-card class="details activity mr-4">
          <div>
            <strong>Atividades para esse serviço</strong>
          </div>
          <div class="mt-4" v-if="activity && activity.data">
            <div
              v-for="(act, index) in activity.data.service_economic_activities"
              :key="index"
            >
              <section
                class="d-flex w-100 justify-content-between align-items-center"
              >
                <a
                  v-b-popover.hover.top="
                    'clique para ver a descrição dessa atividade '
                  "
                  class="mb-0 pointer icon-action"
                  @click="handleShowDetailActivity(act)"
                >
                  {{ act.description }}
                </a>
                <b-icon
                  v-if="false"
                  v-b-popover.hover.top="
                    `Marcar como ${invertValue ? '' : 'não'} atende`
                  "
                  @click="handleClickChange()"
                  :icon="invertValue ? 'x' : 'check'"
                  class="h3 pointer ml-4 mr-1 my-0"
                  :variant="invertValue ? 'danger' : 'success'"
                />
              </section>
              <hr class="mt-1" />
            </div>
          </div>
        </b-card>
      </div>
    </div>

    <b-modal
      id="modal-detail-activity"
      size="md"
      centered
      :title="currentActivity.description"
      hide-footer
    >
      <p>
        {{ currentActivity.comments }}
      </p>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      service_id: "",
      provider_id: "",
      hasSnapshot: false,
      enabled: false,
      invertValue: false,
      isEmpty: false,
      loading: false,
      activity: {},
      currentActivity: {},
    };
  },

  computed: {
    ...mapGetters(["is_preview", "user", "preview_personify", "is_provider"]),
  },
  methods: {
    ...mapActions(["get_snapshot", "set_snapshot", "show_loading"]),
    handleClickChange() {
      this.invertValue = !this.invertValue;
    },
    handleShowDetailActivity(act) {
      this.currentActivity = act;
      this.$bvModal.show("modal-detail-activity");
    },
    getSnapshot() {
      this.loading = true;
      this.show_loading({
        loading: true,
      });
      this.get_snapshot({
        type: "economic-activity",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then((response) => {
        this.enabled = response.enabled;
        if (!response?.created_at) {
          this.hasSnapshot = false;
        } else {
          this.hasSnapshot = true;
          this.activity = response;
        }
        this.show_loading({
          loading: false,
        });
        this.loading = false;
      });
    },
    handleSyncronize() {
      this.$bvModal.show("modal-generating-snapshot");
      this.set_snapshot({
        type: "economic-activity",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then(() => {
        this.$bvModal.hide("modal-generating-snapshot");
        this.hasSnapshot = true;
        this.getSnapshot();
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id || this.$route.query.provider_id;
    this.getSnapshot();
  },
};
</script>
<style lang="scss">
.details {
  &.activity {
    width: 360px;
    overflow: auto;
    @media (max-width: $break-md) {
      width: 100%;
    }
  }
}
</style>
