var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.hasSnapshot && !_vm.loading)?_c('div',{staticClass:"min-h-50 d-flex w-100 align-items-center justify-content-center"},[_c('div',{staticClass:"text-center"},[_c('b-icon',{staticClass:"h1",attrs:{"icon":"calculator"}}),_c('p',{staticClass:"my-3"},[_vm._v("Ainda não há informações de atividades econômicas.")]),(_vm.is_provider && _vm.enabled)?_c('b-button',{on:{"click":function($event){return _vm.handleSyncronize()}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"arrow-down-up"}}),_c('span',[_vm._v("Sincronizar agora")])],1):_vm._e()],1)]):_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('small',{staticClass:"mr-2"},[_vm._v(" Ultima atualização "+_vm._s(_vm._f("dateFull")(_vm.activity.updated_at))+" ")]),(_vm.is_provider && _vm.enabled)?_c('unicon',{staticClass:"pointer small",staticStyle:{"font-size":"12px"},attrs:{"name":"redo","height":"16","width":"16"},on:{"click":function($event){return _vm.handleSyncronize()}}}):_vm._e()],1),_c('div',{staticClass:"mt-3",attrs:{"id":"location-techinical"}},[_c('b-card',{staticClass:"details activity mr-4"},[_c('div',[_c('strong',[_vm._v("Suas atividades")])]),(_vm.activity.data)?_c('div',{staticClass:"mt-4"},_vm._l((_vm.activity.data.provider_economic_activities),function(act,index){return _c('div',{key:index},[_c('section',{staticClass:"d-flex w-100 justify-content-between align-items-center"},[_c('a',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                  'clique para ver a descrição dessa atividade '
                ),expression:"\n                  'clique para ver a descrição dessa atividade '\n                ",modifiers:{"hover":true,"top":true}},{name:"b-modal",rawName:"v-b-modal.modal-detail-activity",modifiers:{"modal-detail-activity":true}}],staticClass:"mb-0 pointer icon-action",on:{"click":function($event){return _vm.handleShowDetailActivity(act)}}},[_vm._v(" "+_vm._s(act.description)+" ")]),(false)?_c('b-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                  `Marcar como ${_vm.invertValue ? '' : 'não'} atende`
                ),expression:"\n                  `Marcar como ${invertValue ? '' : 'não'} atende`\n                ",modifiers:{"hover":true,"top":true}}],staticClass:"h3 pointer ml-4 mr-1 my-0",attrs:{"icon":_vm.invertValue ? 'x' : 'check',"variant":_vm.invertValue ? 'danger' : 'success'},on:{"click":function($event){return _vm.handleClickChange()}}}):_vm._e()],1),_c('hr',{staticClass:"mt-1"})])}),0):_vm._e()]),_c('b-card',{staticClass:"details activity mr-4"},[_c('div',[_c('strong',[_vm._v("Atividades para esse serviço")])]),(_vm.activity && _vm.activity.data)?_c('div',{staticClass:"mt-4"},_vm._l((_vm.activity.data.service_economic_activities),function(act,index){return _c('div',{key:index},[_c('section',{staticClass:"d-flex w-100 justify-content-between align-items-center"},[_c('a',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                  'clique para ver a descrição dessa atividade '
                ),expression:"\n                  'clique para ver a descrição dessa atividade '\n                ",modifiers:{"hover":true,"top":true}}],staticClass:"mb-0 pointer icon-action",on:{"click":function($event){return _vm.handleShowDetailActivity(act)}}},[_vm._v(" "+_vm._s(act.description)+" ")]),(false)?_c('b-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                  `Marcar como ${_vm.invertValue ? '' : 'não'} atende`
                ),expression:"\n                  `Marcar como ${invertValue ? '' : 'não'} atende`\n                ",modifiers:{"hover":true,"top":true}}],staticClass:"h3 pointer ml-4 mr-1 my-0",attrs:{"icon":_vm.invertValue ? 'x' : 'check',"variant":_vm.invertValue ? 'danger' : 'success'},on:{"click":function($event){return _vm.handleClickChange()}}}):_vm._e()],1),_c('hr',{staticClass:"mt-1"})])}),0):_vm._e()])],1)]),_c('b-modal',{attrs:{"id":"modal-detail-activity","size":"md","centered":"","title":_vm.currentActivity.description,"hide-footer":""}},[_c('p',[_vm._v(" "+_vm._s(_vm.currentActivity.comments)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }